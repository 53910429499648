import React, { useState } from "react";
import { Select, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { DateInput } from "@mantine/dates";

// components
import { Label, MainTitle } from "components/GlobalUi/Typography";
import Input from "components/GlobalUi/Input";
import { useMediaQuery } from "@mantine/hooks";

const Index = () => {
  const lS = useMediaQuery("(min-width: 768px)");

  const [value, setValue] = useState(0);

  return (
    <div>
      <div className="basicInformationLabel">
        <MainTitle>SBR CARD NUMBER AND BASIC INFORMATION</MainTitle>
      </div>
      <div className="formStep">
        <Text fz="sm" c="#FE0000" ta="center" mb={20}>
          Please fill out the details of the form below.**
        </Text>
        <form style={{ width: "100%" }}>
          <div style={{ maxWidth: 366, marginBottom: 35 }}>
            <Label required>SBR Card Number</Label>
            <Input
              placeholder="Enter SBR Card Number"
              variant="unstyled"
              required
            />
          </div>
          <div className="inputWrapper">
            <div className="inputTitle">
              <Label required>Title</Label>
              <Select
                placeholder="Choose"
                rightSection={<IconChevronDown size="1rem" />}
                rightSectionWidth={30}
                radius="md"
                size={lS ? "xl" : "sm"}
                styles={{ rightSection: { pointerEvents: "none" } }}
                data={["Mr.", "Ms.", "Mrs."]}
                className="customSelect"
                variant="unstyled"
              />
            </div>
            <div className="formWrapper1">
              <div className="inputForm">
                <Label required>First Name</Label>
                <Input placeholder="Enter First Name" variant="unstyled" />
              </div>
              <div className="inputForm">
                <Label>Middle Name</Label>
                <Input placeholder="Enter Middle Name" variant="unstyled" />
              </div>
              <div className="inputForm">
                <Label required>Last Name</Label>
                <Input placeholder="Enter Last Name" variant="unstyled" />
              </div>
            </div>
          </div>
          <div className="inputWrapper">
            <div className="inputTitle">
              <Label>Suffix</Label>
              <Select
                placeholder="Choose"
                rightSection={<IconChevronDown size="1rem" />}
                rightSectionWidth={30}
                radius="md"
                size={lS ? "xl" : "sm"}
                styles={{ rightSection: { pointerEvents: "none" } }}
                data={["N/A", "Jr.", "Sr."]}
                className="customSelect"
                variant="unstyled"
              />
            </div>
            <div className="formWrapper1">
              <div className="inputForm">
                <Label required>Gender</Label>
                <Select
                  placeholder="Choose"
                  rightSection={<IconChevronDown size="1rem" />}
                  rightSectionWidth={30}
                  radius="md"
                  size={lS ? "xl" : "sm"}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  data={["Male", "Female"]}
                  className="customSelect"
                  variant="unstyled"
                />
              </div>
              <div className="inputForm">
                <Label required>Civil Status</Label>
                <Select
                  placeholder="Choose"
                  rightSection={<IconChevronDown size="1rem" />}
                  rightSectionWidth={30}
                  radius="md"
                  size={lS ? "xl" : "sm"}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  data={["Single", "Married", "Widower"]}
                  className="customSelect"
                  variant="unstyled"
                />
              </div>
              <div className="inputForm">
                <Label required>Citizenship</Label>
                <Input placeholder="Enter Citizenship" variant="unstyled" />
              </div>
            </div>
          </div>
          <div className="inputWrapper">
            <div>
              <Label required>Birthday</Label>
              <DateInput
                valueFormat="MM/DD/YYYY"
                value={value}
                onChange={setValue}
                placeholder="Choose Birthday"
                maw={400}
                mx="auto"
                radius="md"
                size={lS ? "xl" : "sm"}
                className="customSelect"
                variant="unstyled"
              />
            </div>
            <div className="inputForm">
              <Label required>Birthplace</Label>
              <Input
                type="text"
                placeholder="Enter Birthplace "
                variant="unstyled"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
