import React from "react";
import { Title, Text, Image, Modal } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

// style
import "./style.css";

const Index = ({
  opened,
  onClose,
  title,
  subTitle,
  subTitle2,
  icon,
  okBtn,
  noBtn,
}) => {
  const lS = useMediaQuery("(min-width: 768px)");
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={589}
      radius={20}
      centered
      padding={lS ? "20px 50px 50px" : "10px 25px 25px"}
      transitionProps={{ transition: "fade", duration: 400 }}
      overlayProps={{
        opacity: 0.55,
        blur: 3,
      }}
    >
      <div className="modalImage">
        <Image src={icon} alt="" width={114} height={114} mb={20} />
      </div>
      <div className="modalWrapper">
        <Title order={2} fz={lS ? 30 : 22} fw={700} align="center">
          {title}
        </Title>
        <div className="subTitleWrapper">
          <Text fz={lS ? 15 : 13} fw={500} c="#1E1E1E">
            {subTitle}
          </Text>
          <Text fz={lS ? 15 : 13} fw={500} c="#1E1E1E">
            {subTitle2}
          </Text>
        </div>
        <div className="modalButton">
          {okBtn && (
            <button className="okButton" onClick={onClose}>
              <Text fz={lS ? 25 : 18} fw={700} c="#fff">
                {okBtn}
              </Text>
            </button>
          )}

          {noBtn && (
            <button className="noButton" onClick={onClose}>
              <Text fz={lS ? 25 : 18} fw={700} c="#FE0000">
                {noBtn}
              </Text>
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Index;
