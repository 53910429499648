import React from 'react';
import { Image } from '@mantine/core';

import HeaderImage from '../../assets/png/application_solidarity.png'

const Index = () => {
    return (
        <div>
            <Image src={HeaderImage} alt="" />
            
        </div>
    );
};

export default Index;
