import React from "react";
import { Grid, Image, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";

// components
import { MainTitle } from "components/GlobalUi/Typography";

// assets
import documentImg from "assets/png/solar_document-linear.png";

const UploadRequirements = () => {
  const lS = useMediaQuery("(min-width: 768px)");

  return (
    <div>
      <div className="detailsLabel">
        <MainTitle>CAR DETAILS</MainTitle>
      </div>
      <div className="formStep">
        <Text fz="sm" c="#FE0000" ta="center" mb={20}>
          Please fill out the details of the form below.**
        </Text>
        <Text fz={lS ? "xl" : "sm"} fw={600} c="#00349C">
          UPLOAD REQUIREMENTS PHOTO
        </Text>
        <div className="browseContainer">
          <Grid gutter={40}>
            <Grid.Col xs={12} lg={6} ta="center">
              <div className="browseInput">
                <Image src={documentImg} alt="" width={lS ? 190 : 100} />
                <div className="textWrapper">
                  <Text fz={15} fw={600} c="#000" ta="center">
                    Drop your files here. or
                    <Link to="#" className="browseLink">
                      Browse
                    </Link>
                  </Text>
                </div>
              </div>
              <button className="btnLisensya">
                <Text fz={14} fw={700} c="#fff">
                  LISENSYA
                </Text>
              </button>
            </Grid.Col>
            <Grid.Col xs={12} lg={6} ta="center">
              <div className="browseInput">
                <Image src={documentImg} alt="" width={lS ? 190 : 100} />
                <div className="textWrapper">
                  <Text fz={15} fw={600} c="#000" ta="center">
                    Drop your files here. or
                    <Link to="#" className="browseLink">
                      Browse
                    </Link>
                  </Text>
                </div>
              </div>
              <button className="btnLisensya">
                <Text fz={14} fw={700} c="#fff">
                  OR AND CR
                </Text>
              </button>
            </Grid.Col>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default UploadRequirements;
