import React, { useEffect, useState } from "react";
import { Stepper } from "@mantine/core";

// styles
import "./style.css";

// components
import Layout from "containers/Layout";
import Modal from "components/GlobalUi/Modal";
import BasicInformation from "./components/BasicInformation";
import CarDetails from "./components/CarDetails";
import UploadRequirements from "./components/CarDetails/UploadRequirements";

// assets
import smsImg from "assets/png/Sms.png";
import plateImg from "assets/png/Plate.png";
import fileDocument from "assets/png/file_document.png";
import documentDeniedImg from "assets/png/document_denied.png";

const Index = () => {
  const [active, setActive] = useState(0);
  const [isPlateExistModalVisible, setIsPlateExistModalVisible] =
    useState(false);
  const [isVerifyInfoModalVisible, setIsVerifyInfoModalVisible] =
    useState(false);
  const [
    isApplicationApprovedModalVisible,
    setIsApplicationApprovedModalVisible,
  ] = useState(false);
  const [isApplicationDeniedModalVisible, setIsApplicationDeniedModalVisible] =
    useState(false);

  useEffect(() => {
    if (active === 1) {
      setIsPlateExistModalVisible(true);
    }
  }, [active]);

  const handleModal = () => {
    setIsVerifyInfoModalVisible(true);
    // setIsApplicationApprovedModalVisible(true);
    // setIsApplicationDeniedModalVisible(true);
  };

  const handleVerifyCloseModal = async () => {
    await setIsVerifyInfoModalVisible(!isVerifyInfoModalVisible);
    setIsApplicationApprovedModalVisible(true);
  };

  const handleApprovedCloseModal = async () => {
    setIsApplicationApprovedModalVisible(!isApplicationApprovedModalVisible);
    setIsApplicationDeniedModalVisible(true);
  };

  const handleDeniedCloseModal = async () => {
    setIsApplicationDeniedModalVisible(!isApplicationDeniedModalVisible);
  };
  const nextStep = () => {
    setActive((current) => (current < 2 ? current + 1 : current));
  };
  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  return (
    <Layout>
      <div className="formWrapper">
        <Stepper active={active} onStepClick={setActive} breakpoint="sm">
          <Stepper.Step style={{ display: "none" }}>
            <BasicInformation />
          </Stepper.Step>
          <Stepper.Step style={{ display: "none" }}>
            <CarDetails />
          </Stepper.Step>
          <Stepper.Completed style={{ display: "none" }}>
            <UploadRequirements />
          </Stepper.Completed>
        </Stepper>
        <div className="stepBtn">
          <div>
            <button className="proceedButton" onClick={prevStep}>
              Back
            </button>
          </div>
          {active === 2 ? (
            <div>
              {" "}
              <button className="submitButton" onClick={handleModal}>
                SUBMIT
              </button>
            </div>
          ) : (
            <div>
              <button className="proceedButton" onClick={nextStep}>
                PROCEED
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal
        opened={isPlateExistModalVisible}
        title="Plate Exist?"
        subTitle="Click “YES” if you have already own an Plate Number."
        subTitle2="Click “NO” if you don’t have yet Plate Number."
        onClose={() => setIsPlateExistModalVisible(!isPlateExistModalVisible)}
        icon={plateImg}
        okBtn="YES"
        noBtn="NO"
      />
      <Modal
        opened={isVerifyInfoModalVisible}
        title="We are verifying your information"
        subTitle="We will send you an SMS Notification for if you are qualified."
        onClose={handleVerifyCloseModal}
        icon={smsImg}
        okBtn="OK"
      />
      <Modal
        opened={isApplicationApprovedModalVisible}
        title="Application Approved"
        subTitle="We will send you an SMS Notification for your schedule of sticker release."
        onClose={handleApprovedCloseModal}
        icon={fileDocument}
        okBtn="OK"
      />
      <Modal
        opened={isApplicationDeniedModalVisible}
        title="Application Denied"
        subTitle="Please review your application and apply again."
        onClose={handleDeniedCloseModal}
        icon={documentDeniedImg}
        okBtn="APPLY"
        noBtn="CANCEL"
      />
    </Layout>
  );
};

export default Index;
