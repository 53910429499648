import React from "react";
import { Text, Autocomplete } from "@mantine/core";

// components
import { Label, MainTitle } from "components/GlobalUi/Typography";
import Input from "components/GlobalUi/Input";
import { useMediaQuery } from "@mantine/hooks";

const colorData = ["White", "Red", "Blue", "Green", "Yellow", "Black"];

const Index = () => {
  const lS = useMediaQuery("(min-width: 768px)");

  return (
    <div>
      <div className="detailsLabel">
        <MainTitle>CAR DETAILS</MainTitle>
      </div>
      <div className="formStep">
        <Text fz="sm" c="#FE0000" ta="center" mb={20}>
          Please fill out the details of the form below.**
        </Text>
        <form>
          <div className="inputWrapper">
            <div className="inputForm">
              <Label required>Plate Number</Label>
              <Input
                placeholder="Enter Plate Number"
                variant="unstyled"
                required
              />
            </div>
            <div className="inputForm">
              <Label required>Car Model</Label>
              <Input placeholder="Enter Car Model" variant="unstyled" />
            </div>
            <div className="inputForm">
              <Label required>Color</Label>
              <Autocomplete
                placeholder="Enter Color"
                variant="unstyled"
                data={colorData}
                className="autoCompleteInput"
                radius="md"
                size={lS ? "xl" : "sm"}
              />
            </div>
          </div>
          <div className="routeFlex">
            <Label required>Route</Label>
            <Input placeholder="Enter Route" variant="unstyled" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
