import React from "react";
import { Container } from "@mantine/core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Index = ({ children }) => {
  return (
    <>
      <Header />
      <Container size={1346}>{children}</Container>
      <Footer />
    </>
  );
};

export default Index;
