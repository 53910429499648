import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Image, Text } from "@mantine/core";

// styles
import "./style.css";

// assets
import PhSeal from "assets/png/ph_seal.png";
import Paper from "assets/png/paper.png";
import SolarCall from "assets/png/solar_call-chat.png";
import { useMediaQuery } from "@mantine/hooks";

const Index = () => {
  const lS = useMediaQuery("(min-width: 768px)");
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="footerWrapper">
        <Container size={lS ? 1600 : 1300} className="footerContainer">
          <div className="footerContainerLeft">
            <Text fz="13px" fs="normal"  fw={500}>Powered by:</Text>
            <Text fz='15px' fs="normal" fw={700}>LUNSOD NG BACOOR</Text>
            <Text fz="13px" fw={500} mt='10px'>
              © 2023 - Bacoor Pre-Registration. All rights reserved.
            </Text>
          </div>
          <div className="footerContainerRight">
            <div className="apply" onClick={() => navigate("/apply")}>
              <Image
                src={Paper}
                width={lS ? 25 : 18}
                height={lS ? 25 : 18}
                alt=""
              />
              <Text fz={lS ? "xl" : "md"} fw={600} c="#00349C">
                Apply
              </Text>
            </div>
            <div className="apply" onClick={() => navigate("/contact-us")}>
              <Image
                src={SolarCall}
                width={lS ? 30 : 22}
                height={lS ? 30 : 22}
                alt=""
              />
              <Text
                fz={lS ? "xl" : "md"}
                fw={600}
                c="#00349C"
                className="contactText"
              >
                Contact
              </Text>
            </div>
          </div>
          <Image
            src={PhSeal}
            width={141}
            height={113}
            className="imagePh"
            alt=""
          />
        </Container>
      </div>
    </div>
  );
};

export default Index;
