import React from "react";
import { Text, Image, Container, Grid, Title } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";

// style
import "./style.css";

// components
import Footer from "components/Footer";

// assets
import editIcon from "assets/png/edit-light.png";
import callIcon from "assets/png/solar_call-chat-rounded-bold-light.png";
import { useMediaQuery } from "@mantine/hooks";

const Index = () => {
  const navigate = useNavigate();

  const lS = useMediaQuery("(min-width: 768px)");

  return (
    <div className="homepage">
      <div style={{ height: "100vh" }}>
        <Container size={lS ? 1600 : 1300} className="navWrapper">
          <div className="navRightContainer"></div>
          <div className="navRightContainer">
            <Link to="/apply" className="navLink">
              <Image src={editIcon} alt="edit" width={30} height={30} />
              <Text fz="xl" c="#fff" fw={600}>
                Apply
              </Text>
            </Link>
            <Link to="/contact-us" className="navLink">
              <Image src={callIcon} alt="edit" width={30} height={30} />
              <Text fz="xl" c="#fff" fw={600}>
                Contact
              </Text>
            </Link>
          </div>
        </Container>
        <Container size={2500}>
          <Grid>
            <Grid.Col md={6} lg={5}></Grid.Col>
            <Grid.Col md={6} lg={7} pl={lS ? 35 : 0}>
              <Title  fw={800} fs="italic" c="#fff" className='welcomeTitle'>
                Welcome to
              </Title>
              <Title  fw={800} fs="italic" c="#fff" className='appTitle'>
                Application Solidarity Sticker
              </Title>
              <Title  fw={400} fs="italic" c="#fff" pt={10} className='preRegisterTitle'>
                Pre - Registration Website
              </Title>
              <Text
                fw={500}
                fs="italic"
                c="#fff"
                pt={lS ? 65 : 32}
                className="subTitle"
              >
                Get your solidarity sticker in just few steps.
              </Text>
              <button className="applyBtn" onClick={() => navigate("/apply")}>
                <Text fz={lS ? 25 : 18} fw={700} c="#fff">
                  APPLY
                </Text>
              </button>
            </Grid.Col>
          </Grid>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
