import React from "react";
import { Title } from "@mantine/core";
import { Text } from "@mantine/core";

// style
import "./style.css";
import { useMediaQuery } from "@mantine/hooks";

export const MainTitle = ({ children }) => {
  return <Title className="customTitle">{children}</Title>;
};

export const Label = ({ children, required }) => {
  const lS = useMediaQuery("(min-width: 100em)");

  return (
    <Text className="customText">
      {children} {required && <span style={{ color: "red" }}>*</span>}
    </Text>
  );
};
