import { MantineProvider } from "@mantine/core";

// components
import React from "react";
import AppRouter from "./AppRouter";

function App() {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        fontFamily: "Inter, sans-serif",
        fontFamilyMonospace: "Inter, sans-serif",
        headings: { fontFamily: "Inter, sans-serif" },
      }}
    >
      <AppRouter />
    </MantineProvider>
  );
}

export default App;
