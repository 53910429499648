import React from "react";
import { TextInput } from "@mantine/core";

// styles
import "./style.css";
import { useMediaQuery } from "@mantine/hooks";

const Index = ({ ...rest }) => {
  const lS = useMediaQuery("(min-width: 768px)");
  return (
    <TextInput
      {...rest}
      radius="md"
      size={lS ? "xl" : "sm"}
      className="customInput"
    />
  );
};

export default Index;
