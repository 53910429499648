import React from "react";
import { Title, Image, Grid } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
// components
import Layout from "containers/Layout";

// style
import "./style.css";

// assets
import Mail from "assets/png/mail.png";
import Call from "assets/png/solar_call-chat-bold.png";
import Pin from "assets/png/pin.png";

const Index = () => {
  const lS = useMediaQuery("(min-width: 768px)");

  return (
    <Layout>
      <div>
        <Title ta="center" order={lS ? 2 : 4}>
          CONTACT US
        </Title>
      </div>
      <div className="contactUsWrapper">
        <Grid gutter={lS ? 80 : 30} grow pt={lS ? 100 : 50} pb={lS ? 100 : 50}>
          <Grid.Col xs={12} lg={4}>
            <div className="flexContainer">
              <div className="roundedContainer">
                <Image src={Mail} alt="" width={lS ? 93 : 43} />
              </div>
              <Title ta="center" order={lS ? 3 : 5}>
                Application@bacoor.gov.ph
              </Title>
            </div>
          </Grid.Col>
          <Grid.Col xs={12} lg={4}>
            <div className="flexContainer">
              <div className="roundedContainer">
                <Image src={Call} alt="" width={lS ? 62 : 32} />
              </div>
              <Title ta="center" order={lS ? 3 : 5}>
                +63 (046) 434 1111 loc 102
              </Title>
            </div>
          </Grid.Col>
          <Grid.Col xs={12} lg={4}>
            <div className="flexContainer">
              <div className="roundedContainer">
                <Image src={Pin} alt="" width={lS ? 62 : 32} />
              </div>
              <Title ta="center" order={lS ? 3 : 5}>
                Lower Ground Floor,Bacoor Government Center,Bacoor Blvd., Brgy.
                Bayanan, City of Bacoor
              </Title>
            </div>
          </Grid.Col>
        </Grid>
      </div>
    </Layout>
  );
};

export default Index;
